<template>
  <div v-bind:id="id" class="bg-white overflow-hidden shadow-lg rounded-lg divide-y divide-gray-200 px-4 py-5 sm:px-6" data-component="TaskSchedule">
    <div class="grid grid-rows-auto grid-cols-12 flex gap-4">

      <div class="row-start-1 row-span-1 col-start-1 col-end-6 ...">
        <h3 class="text-gray-900 text-lg font-medium px-4 py-4 sm:p-6">{{ name }}</h3>
      </div>

      <div class="row-start-1 row-span-1 col-start-6 col-end-12 ...">
        <div ref="birdseye_schedule_container" class="px-4 py-4 sm:p-6"></div>
      </div>

      <div class="row-start-2 row-span-1 col-start-1 col-end-11 ...">
        <div ref="aggregated_schedule_container" class="px-4 py-4 sm:p-6"></div>
      </div>

      <div class="row-start-2 row-span-1 col-start-12 col-end-12 ...">
        <div
          @click="toggleDisplaySims"
          class="relative inline-flex h-5 w-10 cursor-pointer items-center justify-center rounded-full transition-colors duration-200 ease-in-out"
          :class="{ 'bg-gray-200': display_sims, 'bg-gray-400': !display_sims }"
          data-component="ToggleSwitch"
        >
          <span class="sr-only">Use setting</span>
          <span
            class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
            :class="{ 'translate-x-5': display_sims, 'translate-x-0': !display_sims }"
          />
        </div>
      </div>

      <div v-show="display_sims" class="row-start-3 row-span-1 col-start-1 col-end-11 ..." data-component="ActiveDays">
        <div ref="simulated_schedules_container" class="px-4 py-4 sm:p-6"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash';
import * as Plot from "@observablehq/plot";
import aggregate_with_count from '../../js/lib/calendars/aggregate-with-count.js'
</script>

<script>
//range = {
//    start: { day: 1, month: 1, year: 2024 },
//    finish: { day: 11, month: 1, year: 2024 },
//}
const generateDateArray = function(range) {
    const { start, finish } = range;
    const result = [];

    const startDate = new Date(start.year, start.month - 1, start.day);
    const endDate = new Date(finish.year, finish.month - 1, finish.day);

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        result.push({
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        });
    }

    return result;
}

// get the first of the month date x months in the past
function prior_months_start(dateObj, months) {
  // Create a Date object from the input
  const { day, month, year } = dateObj;
  const date = new Date(year, month - 1, day);

  // Set the date to the first day of the current month
  date.setDate(1);

  // Set the month based on input
  date.setMonth(date.getMonth() - months);

  // Return the first day of the previous month
  return {
    day: 1,
    month: date.getMonth() + 1, // JavaScript months are 0-based
    year: date.getFullYear(),
  };
}

// get the first of the month date x months in the future
function next_months_start(dateObj, months) {
  const { day, month, year } = dateObj;

  // Create a Date object from the input
  const date = new Date(year, month - 1, day);

  // Set the month based on input
  date.setMonth(date.getMonth() + months);

  // Set the day to the first of the month
  date.setDate(1);

  // Return the first day of the next month
  return {
    day: 1,
    month: date.getMonth() + 1, // JavaScript months are 0-based
    year: date.getFullYear(),
  };
}

// helper function for date plot
const plot_date = function(day, month, year){
  return new Date(year, month - 1, day);
}

const is_same_day = function(a, b){
  return a.day == b.day && a.month == b.month && a.year == b.year
}

export default {
  name: "CombinedTaskSchedule",
  props: ['id', 'name', 'input'],
  components: { },
  data() {
    return {
      display_sims: false
    };
  },
  mounted() {
    const DS = this.data_structures();
    this.renderBirdsEyeSchedule(DS);
    this.renderAggregatedSchedule(DS);
    this.renderSimulatedSchedules(DS);
  },
  updated() {
    const DS = this.data_structures();
    this.renderBirdsEyeSchedule(DS);
    this.renderAggregatedSchedule(DS);
    this.renderSimulatedSchedules(DS);
  },
  methods: {
    data_structures() {
      // here we compute all the core data structures for the component
      // then we can hand the finshed object around to the various
      // sub-visuals for rendering

      // initialize the DS object. this will be handed to other methods
      // for rendering
      const DS = {}


      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // INPUTS
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      // get the simulated schedules
      const simulated_schedules = this.input.simulated_schedules

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // COMBINED SIMULATION SCHEDULES
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      // get the sim ids for indexing
      const sim_ids = _.keys(simulated_schedules)

      // merge all active days from the sim schedules into the one combined array
      let combined_sim_schedules = _.flatten(_.map(sim_ids, (sim_id) => {
        return simulated_schedules[sim_id]['active_days']
      }))

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // ACTIVE DAY AGGREGATIONS
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      // compute and set the aggregated schedule from the simulated schedules

      // aggregate with count helper to count the active days
      const active_days = aggregate_with_count(combined_sim_schedules)

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // EARLIEST AND LATEST ACTIVE DAY
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      // it will useful to know the earliest latest day the tasks is active
      // when considering all possible simulations. This gives us a working range
      // for downstream calculations.

      // compute the first and last ad
      const first_ad = _.first(active_days)
      const last_ad  = _.last(active_days)

      const earliest_active_day = {
        day:   first_ad['day'],
        month: first_ad['month'],
        year:  first_ad['year'],
      }

      const latest_active_day = {
        day:   last_ad['day'],
        month: last_ad['month'],
        year:  last_ad['year'],
      }

      // - - - - - - - - - - - - - - - - - - - - - - - - -
      // CONSIDERED DAYS RANGE
      // - - - - - - - - - - - - - - - - - - - - - - - - -

      // considered days are a subset of days that are relevent for viewing the task
      // for example a project may span 2.5 years and the active task days are established
      // in the context of the simulation within this range.
      //
      // However in practice the user will only want to view calendar details of this task
      // within a reasonable time window say 3 months to.

      const earliest_considered_date = prior_months_start(earliest_active_day, 1)
      const latest_considered_date   = next_months_start(latest_active_day, 1)

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // ALL POSSIBLED DAYS RANGE
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      // build the possible day range from the considered dates
      const possible_day_range = {start: earliest_considered_date, finish: latest_considered_date }

      // generate a full calendar span  of days
      const all_possible_days = generateDateArray(possible_day_range)

      // add probability to full span of days
      const all_possible_days_with_probability = _.map(_.cloneDeep(all_possible_days), function(d){
        return  _.assign(d, {probability: 0.0})
      })

      // - - - - - - - - - - - - - - - - - - - - - - - - -
      // SIMULATION CELLS
      // - - - - - - - - - - - - - - - - - - - - - - - - -

      // data structure for plotting simulation cells
      const simulations_cells = [];

      _.each(_.keys(simulated_schedules), function(sim_id){
        _.each(all_possible_days, function(day){

          // grab just day,month,year without vue proxy stuff
          const active_day_group = _.map(simulated_schedules[sim_id]['active_days'], function(ad){
            return {day: ad.day, month: ad.month, year: ad.year}
          })

          // check if they day is in the active day group
          let value;
          if(_.some(active_day_group, day)){
            value = true
          }else{
            value = false
          }

          simulations_cells.push({date: day, sim_id: sim_id, active: value})
        })
      })

      DS['simulations_cells'] = simulations_cells

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // ACTIVE DAY PROBABILITY
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      // compute the total number of simulations for downstream
      const sim_count = _.size(sim_ids)

      // set the 'probability' metric
      // probability defined as - the total the number of times an active day
      // appears divided by the total number of simulations
      _.each(active_days, (active_day) => {
        const probability = _.round(active_day['count'] / sim_count, 2)
        active_day = _.assign(active_day, { probability: probability });
      })

      // init with all possible days
      const combined_days = _.cloneDeep(all_possible_days_with_probability);

      // combined days is the span days and the active days
      _.each(combined_days, function(d){

        const active_day = _.find(active_days, function(ad) {
          return ad.day == d.day && ad.month == d.month && ad.year == d.year
        })

        // set the probability to the active day probability if it exists
        if(!_.isUndefined(active_day)){
          d['probability'] = active_day['probability']
        }
      })

      // the final data is created by merging the active days from the input
      // into the generated dates from the span
      DS['combined_days'] = combined_days

      // display ticks above the days where there is non-zero probability
      const DISPLAY_THRESHOLD = 0.01
      const positive_probability_days = _.filter(combined_days, function(d){ return d.probability > DISPLAY_THRESHOLD })
      DS['positive_probability_days'] = positive_probability_days

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // BIRDSEYE INPUT
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      const earliest_birdseye_date = prior_months_start(earliest_considered_date, 4)
      const latest_birdseye_date   = next_months_start(latest_considered_date, 4)

      // set the start and stop of the brids eye view to the full project span
      DS['full_span_interval'] =  {start: earliest_birdseye_date, finish: latest_birdseye_date}

      // use the established active days to set the visible span (focus box) for the bridseye view
      DS['visible_span_interval'] = {start: earliest_considered_date, finish: latest_considered_date}

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // MONTH BOUNDRIES
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      // get the first of the month days
      const month_boundries = _.filter(combined_days, function(d){return d.day == 1})

      DS['month_boundries'] = month_boundries

      // - - - - - - - - - - - - - - - - - - - - - - - - - 
      // FINAL DS
      // - - - - - - - - - - - - - - - - - - - - - - - - - 

      return DS
    },
    renderBirdsEyeSchedule(DS) {
      const container = this.$refs.birdseye_schedule_container;

      // Clear any existing plot
      container.innerHTML = "";

      // Dynamically get the container's width
      const containerWidth = container.offsetWidth;

      const full_span_interval = [
        {
          interval: 'Full Span',
          start: DS.full_span_interval.start,
          finish:   DS.full_span_interval.finish,
        },
      ]

      const visible_span_interval = [
        {
          interval: 'Visible Span',
          start: DS.visible_span_interval.start,
          finish:   DS.visible_span_interval.finish,
        },
      ]

      const plot = Plot.plot({
        width: containerWidth,
        padding: 0,
        axis: null,
        y: {
          grid: false,
        },
        x: {
          axis: "bottom",
          grid: false,
          type: "time",
        },
        marks: [
          Plot.rect(visible_span_interval, {
            x1: (d) => plot_date(d.start.day, d.start.month, d.start.year),
            x2: (d) => plot_date(d.finish.day, d.finish.month, d.finish.year),
            stroke: 'black',
            inset: 7.0,
          }),
          Plot.rect(full_span_interval, {
            x1: (d) => plot_date(d.start.day, d.start.month, d.start.year),
            x2: (d) => plot_date(d.finish.day, d.finish.month, d.finish.year),
            fill: 'transparent',
          }),
        ]
      })

      // Append the plot to the container
      container.appendChild(plot);

    },
    renderAggregatedSchedule(DS) {
      const container = this.$refs.aggregated_schedule_container;

      // Clear any existing plot
      container.innerHTML = "";

      // Dynamically get the container's width
      const containerWidth = container.offsetWidth;

      // Generate the plot
      const plot = Plot.plot({
        width: containerWidth,
        aspectRatio: 1,
        marks: [
          // plot the first of the month boundry
          Plot.gridX(DS.month_boundries, {x: (d) => plot_date(d.day, d.month, d.year), strokeDasharray: "2", strokeOpacity: 1}),
          Plot.tickY(DS.positive_probability_days, {x: (d) => plot_date(d.day, d.month, d.year), y: 1.00, strokeOpacity: 0.4}),
          Plot.barY(
            DS.combined_days,
            {
              x: (d) => plot_date(d.day, d.month, d.year),
              y: (d) => d.probability,
              fill: "black",
              inset: 1.0,
            }
          )
        ],
        y: {
          grid: false,
          reverse: false,
          axis: null,

        },
        x: {
          domain: DS.combined_days.map(d => plot_date(d.day, d.month, d.year))
        },
      });

      // Append the plot to the container
      container.appendChild(plot);
    },
    renderSimulatedSchedules(DS) {
      const container = this.$refs.simulated_schedules_container;

      // Clear any existing plot
      container.innerHTML = "";

      // Dynamically get the container's width
      const containerWidth = container.offsetWidth;

      const plot = Plot.plot({
        width: containerWidth,
        aspectRatio: 2.0,
        padding: 0,
        x: {type: "band"},
        y: {
          axis: null,
        },
        marks: [
          Plot.gridX(DS.month_boundries, {x: (d) => plot_date(d.day, d.month, d.year), strokeDasharray: "2", strokeOpacity: 1}),
          Plot.cell(DS.simulations_cells, {x: (d) => plot_date(d.date.day, d.date.month, d.date.year), y: "sim_id", fill: (d) => (d.active ? 'black' : 'transparent'), inset: 1.0, interval: "day"}),
        ]
      })

      // Append the plot to the container
      container.appendChild(plot);
    },
    birds_eye_input() {
      return {
        "full_span_interval":{
          "start": {
            day: 1, month: 1, year: 2025
          },
          "finish": {
            day: 1, month: 6, year: 2027
          },
        },
        "visible_span_interval":{
          "start": {
            day: 5, month: 2, year: 2026
          },
          "finish": {
            day: 5, month: 8, year: 2026
          },
        },
      }
    },
    toggleDisplaySims() {
      this.display_sims = !this.display_sims;
    },
  },
}
</script>
