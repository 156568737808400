import _ from 'lodash';

const aggregate_with_count = (dates) => {
  // Group by date string
  const groupedDates = _.groupBy(dates, (date) => `${date.day}-${date.month}-${date.year}`);

  // Map to the desired format
  const formated = _.map(groupedDates, (group, key) => {
    const [day, month, year] = key.split('-').map(Number);
    return {
      day: day,
      month: month,
      year: year,
      count: group.length,
    };
  });

  return _.orderBy(formated, ['year', 'month', 'day'], ['asc', 'asc', 'asc']);
};

export { aggregate_with_count as default };
