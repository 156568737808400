<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul v-if="schedules_present" role="list" class="space-y-3" data-component="TaskSchedules">
    <li v-for="id in task_ids()">
      <CombinedTaskSchedule
        :id="id"
        :name="task_name(id)"
        :input="input(id)"
      ></CombinedTaskSchedule>
    </li>
  </ul>
  <ul v-else role="list" class="space-y-3">
    <li>
      <NullComponent
        component_id='schedule'
        heading='Missing Task Schedules'
      ></NullComponent>
    </li>
  </ul>
</template>

<script>

// Display
import _ from 'lodash';
import CombinedTaskSchedule from './CombinedTaskSchedule.vue'
import NullComponent from '../null/NullComponent.vue';

export default {
  components: {
    CombinedTaskSchedule: CombinedTaskSchedule,
    NullComponent: NullComponent,
  },
  methods: {
    task(id) {
      return this.tasks[id]
    },
    task_name(id) {
      return this.tasks[id]['name']
    },
    task_ids() {
      return _.keys(this.task_schedules)
    },
    input(id) {
      return {
        calendar_span:       this.task_schedules[id]['calendar_span'],
        simulated_schedules: this.task_schedules[id]['simulated_schedules'],
      }
    },
  },
  computed: {
    schedules_present() {
      return !_.isEmpty(this.$store.getters.task_schedules)
    },
    tasks() {
      return this.$store.getters.tasks
    },
    task_schedules() {
      return this.$store.getters.task_schedules
    },
  },
}
</script>
